import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import * as packageJson from '../package.json';
import Playground from '../../../src/Playground';
import { UiTooltip } from '@uireact/tooltip';
import { UiText } from '@uireact/text';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1 {...{
      "id": "uitooltip"
    }}>{`UiTooltip`}</h1>
    <sup>
  <a href="https://github.com/inavac182/ui-react/blob/main/packages/tooltip/" target="_blank">
    v{packageJson.version}
  </a>
    </sup>
    <blockquote>
      <p parentName="blockquote">{`Component used to render extra information on top of texts or icons.`}</p>
    </blockquote>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <blockquote>
      <p parentName="blockquote">{`npm i @uireact/foundation @uireact/tooltip`}</p>
    </blockquote>
    <h2 {...{
      "id": "above-a-text-element"
    }}>{`Above a text element`}</h2>
    <Playground __position={1} __code={'<UiTooltip text=\"Tooltip! 🐣\">\n  <UiText>Tooltip on top</UiText>\n</UiTooltip>\n<br />\n<UiTooltip text=\"Tooltip! 💋\" position=\"bottom\" category=\"secondary\">\n  <UiText>Tooltip on bottom</UiText>\n</UiTooltip>\n<br />\n<UiTooltip text=\"Tooltip! 🫶\" position=\"left\" category=\"tertiary\">\n  <UiText>Tooltip on left</UiText>\n</UiTooltip>\n<br />\n<UiTooltip\n  text=\"Tooltip! 🌶️\"\n  position=\"right\"\n  category=\"positive\"\n  inverse={false}\n>\n  <UiText>Tooltip on right</UiText>\n</UiTooltip>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiTooltip,
      UiText,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiTooltip text="Tooltip! 🐣" mdxType="UiTooltip">
    <UiText mdxType="UiText">Tooltip on top</UiText>
  </UiTooltip>
  <br />
  <UiTooltip text="Tooltip! 💋" position="bottom" category="secondary" mdxType="UiTooltip">
    <UiText mdxType="UiText">Tooltip on bottom</UiText>
  </UiTooltip>
  <br />
  <UiTooltip text="Tooltip! 🫶" position="left" category="tertiary" mdxType="UiTooltip">
    <UiText mdxType="UiText">Tooltip on left</UiText>
  </UiTooltip>
  <br />
  <UiTooltip text="Tooltip! 🌶️" position="right" category="positive" inverse={false} mdxType="UiTooltip">
    <UiText mdxType="UiText">Tooltip on right</UiText>
  </UiTooltip>
    </Playground>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <Props of={UiTooltip} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      